.header {
  width: 100%;
  background-color: #F66F01;
}
.header_cover img {
  width: 100%;
  height: 100%;
}
.six_characteristics {
  padding-top: 0.5rem;
}
.serviceArea, .slogan {
  padding-top: 2rem;
}
.six_characteristics_title,
.introduction_title,
.serviceArea_title,
.slogan_title,
.case_title {
  font-size: 1.7rem;
  font-weight: bold;
  color: #F66F01;
  text-align: center;
}
.first_three_characteristics, .serviceArea_content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
}
.orangeFont {
  color: #F66F01;
}
.introduction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}
.introduction_paragraph {
  letter-spacing: 0.06rem;
}
.serviceArea_Img {
  width: 50%;
}
.serviceArea_Img img {
  width: 100%;
}
.slogan_content {
  width: 100%;
  background-color: #F66F01;
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem 0 1rem 0;
}
.slogan_box {
  background-color: white;
  padding: 0.8rem 0.8rem 0 0.8rem;
  border-radius: 0.8rem;
}
.slogan_box_title {
  font-weight: bold;
  padding-bottom: 0.5rem;
}
.slogan_box_paragraph {
  font-size: 0.9rem;
  padding-bottom: 0.8rem;
}
.case_content {
  width: 100%;
  background-color: #F66F01;
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* padding: 1rem 0 1rem 0; */
}
.case_box {
  background-color: #F66F01;
  border-radius: 0.8rem;
}
.case_box_title {
  display: flex;
  font-weight: bold;
  /* padding-bottom: 0.5rem; */
}
.introduction_content {
  margin-top: 1rem;
}
.avatar img{
  border-radius:50%;
}
.case_box_paragraph {
  font-size: 0.9rem;
  /* padding-bottom: 0.8rem; */
}
.btn_content {
  padding-left: 10px;
}
.ant-modal-footer {
  display: none;
}
@keyframes shake {
  0% { transform: translateX(0); }
  10% { transform: translateX(-5px); }
  20% { transform: translateX(5px); }
  30% { transform: translateX(-5px); }
  40% { transform: translateX(5px); }
  50% { transform: translateX(0); }
  100% { transform: translateX(0); }
}
.shaking {
  animation: shake 0.5s; /* 应用抖动动画 */
}