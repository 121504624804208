/* 响应式根元素字体大小 */
@media screen and (min-width: 320px) {
  html {
    font-size: 15px;
  }
  .header {
    height: 20rem;
  }
  .header_cover {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  .header_cover img {
    width: 100%;
    height: 100%;
  }
  .first_three_characteristics {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .characteristic_container {
    width: 100%;
    height: 8.5rem;
    border-radius: 0.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    margin: 0.5rem 0;
  }
  .six_characteristics {
    padding-bottom: 2rem;
  }
  .six_characteristics_title, .serviceArea_title, .introduction_title{
    font-size: 1.5rem;
  }
  .six_characteristics_title {
    padding-top: 1.5rem;
  }
  .characteristic_title {
    font-weight: 700;
    font-size: 1.2rem;
  }
  .characteristic_line {
    width: 40%;
    height: 0.25rem;
    background-color: #F66F01;
    margin-top: 0.2rem;
  }
  .title_line {
    width: 90%;
    height: 0.1rem;
    background-color: rgb(160, 161, 161);
    margin: 0.5rem auto;
  }
  .characteristic_content {
    font-size: 1.0rem;
    margin-top: 0.5rem;
  }
  .introduction_paragraph {
    font-size: 1.0rem;
    padding: 0 1.5rem;
    margin-bottom: 1.3rem;
  }
  .introduction_content {
    padding-bottom: 5rem;
  }
  .serviceArea_box {
    width: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
  }
  .slogan_content {
    width: 100%;
    background-color: #F66F01;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0
  }
  .slogan_box {
    width: 22rem;
  }
  .slogan_box2 {
    margin-top: 1rem;
  }
  .slogan_box_title {
    text-align: center;
    font-weight: bold;
    font-size: 1.3rem;
    padding-bottom: 1rem;
  }
  .slogan_box_paragraph {
    font-size: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
  }
  .case_content {
    width: 100%;
    background-color: #F66F01;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;
  }
  .case_box {
    width: 22rem;
    margin-top: 1rem;
    /* padding-bottom: 1rem; */
  }
  .case_box_subtitle {
    color: white;
    font-weight: 400;
    font-size: 0.9rem;
    padding: 0.2rem 0 0.2rem 1rem;
    line-height: 1.8rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background-color: #3399FF;
    box-shadow: #696969;
  }
  .case_box_container {
    background-color: white;
    border-radius: 0 0 0.5rem 0.5rem;
  }
  .case_box_profile {
    padding-top: 0.5rem;
  }
  .case_box_profile {
    display: flex;
  }
  .avatar {
    padding-top: 0.5rem;
    padding-left: 1rem;
  }
  .avatar img{
    width: 3rem;
  }
  .owner {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
  }
  .owner_name {
    font-size: 1.1rem;
    font-weight: 600;
    padding-left: 0.5rem;
    padding-top: 0.65rem;
  }
  .theme {
    color: #696969;
    font-size: 1rem;
    /* font-weight: 500; */
    padding-left: 0.5rem;
    margin-top: 0.3rem;
  }
  .speech_content {
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 1rem;
    padding: 0rem 1rem 1.5rem 1rem;
    line-height: 1.8rem;
  }
  .whatsapp_icon {
    font-size: 1.5rem;
  }
  .search_btn {
    position: fixed;
    width: 20rem;
    height: 4rem;
    /* text-align: center; */
    left:0;
    right:0;
    bottom: 1rem;
    margin:0 auto;
    font-size: 1.2rem;
    font-weight: bolder;
    color: white;
    /* background-color: #1aad17; */
    border-radius: 40px;
    line-height: 3rem;
  }
}
@media screen and (min-width: 640px) {
  html {
    font-size: 16px;
  }
  .header {
    height: 21rem;
  }
  .header_cover {
    width: 50%;
    height: 100%;
    margin: 0 auto;
  }
  .header_cover img {
    width: 100%;
    height: 100%;
  }
  .first_three_characteristics {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 1rem;
  }
  .characteristic_container {
    width: 26%;
    height: 9rem;
    border-radius: 0.5rem;
    text-align: center;
    padding-left: 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .characteristic_title {
    font-weight: 700;
    font-size: 0.85rem;
  }
  .characteristic_line {
    width: 50%;
    height: 0.2rem;
    background-color: #F66F01;
    margin-top: 0.2rem;
  }
  .title_line {
    width: 47%;
    height: 0.1rem;
    background-color: rgb(160, 161, 161);
    margin: 0.5rem auto;
  }
  .characteristic_content {
    font-size: 0.8rem;
    margin-top: 1rem;
  }
  .serviceArea_box {
    width: 14rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
  }
  .slogan_content {
    width: 100%;
    background-color: #F66F01;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 0;
  }
  .slogan_box {
    width: 17rem;
    /* margin-top: 1rem; */
  }
  .slogan_box2 {
    margin-top: 0rem;
  }
  .slogan_box_title {
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    padding-bottom: 1rem;
  }
  .slogan_box_paragraph {
    font-size: 0.8rem;
    padding-left: 1rem;
    padding-bottom: 1.5rem;
  }
  .case_content {
    width: 100%;
    background-color: #F66F01;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 1rem;
    /* padding: 1rem 0 2rem 0; */
  }
  .case_box {
    width: 17.5rem;
    /* height: 13rem; */
  }
  .case_box_subtitle {
    font-weight: 500;
    font-size: 0.7rem;
    padding-left: 1rem;
    padding-top: 0.2rem;
    background-color: #3399FF;
    /* box-shadow: 1px 1px 2px rgb(0,0,0,0.8); */
    border-radius: 0.3rem 0.3rem 0 0;
    line-height: 1.5rem;
    color: white;
    padding-bottom: 0.2rem;
  }
  .case_box_container {
    background-color: white;
    height: 10rem;
    border-radius: 0 0 0.3rem 0.3rem;
  }
  .case_box_profile {
    display: flex;
    /* margin-top: 0.1rem; */
    padding-top: 0.5rem;
  }
  .speech_content {
    font-size: 0.7rem;
    padding: 0rem 1rem 1.5rem 1rem;
    line-height: 1.5rem;
  }
  .introduction_title {
    margin-top: 0.8rem;
  }
  .introduction_paragraph {
    font-size: 1.1rem;
  }
  .introduction_content {
    padding-bottom: 4rem;
  }
  .avatar {
    padding-top: 0.3rem;
    padding-left: 1rem;
  }
  .avatar img{
    width: 2.5rem;
  }
  .owner {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
  }
  .owner_name {
    font-size: 0.8rem;
    font-weight: 600;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
  }
  .theme {
    color: #696969;
    font-size: 0.7rem;
    /* font-weight: 500; */
    padding-left: 0.5rem;
    margin-top: 0.3rem;
  }
  .whatsapp_icon {
    font-size: 1.3rem;
  }
  .search_btn {
    position: fixed;
    width: 15rem;
    height: 3rem;
    /* text-align: center; */
    left:0;
    right:0;
    bottom: 1rem;
    margin:0 auto;
    font-size: 0.9rem;
    font-weight: bolder;
    color: white;
    line-height: 2.55rem;
    /* background-color: #1aad17; */
    border-radius: 40px;
  }
  
}
@media screen and (min-width: 768px) {
  html {
    font-size: 18px;
  }
  /* .header {
    height: 18rem;
  } */
}
@media screen and (min-width: 1024px) {
  html {
    font-size: 20px;
  }
  /* .header {
    height: 19rem;
  } */
}
@media screen and (min-width: 1280px) {
  html {
    font-size: 23px;
  }
  /* .header_cover {
    width: 100%;
  } */
}
@media screen and (min-width: 1366px) {
  html {
    font-size: 24px;
  }
  /* .header_cover {
    width: 100%;
  } */
}
@media screen and (min-width: 1440px) {
  html {
    font-size: 25px;
  }
  /* .header_cover {
    width: 100%;
  } */
}
@media screen and (min-width: 1680px) {
  html {
    font-size: 28px;
  }
  /* .header_cover {
    width: 100%;
  } */
}
@media screen and (min-width: 1920px) {
  html {
    font-size: 33px;
  }
  /* .header_cover {
    width: 100%;
  } */
}
